var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"4f4111fa2e101829ac0e07196e29e427582c13eb"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

import config from '@app/config';
import {SantaResponseError} from '@app/utils/error';
import {ToeicSpeakingResponseError} from '@santa-web/service-common/lib/toeic-speaking';

Sentry.init({
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
  ],
  dsn: config.sentryDsn,
  environment: config.env,
  enabled: config.env === 'production',
  tracesSampleRate: 0.1,
  normalizeDepth: 10,
  beforeSend: (event, hint) => {
    const e = hint?.originalException;
    if (e instanceof SantaResponseError) {
      const requestUrl = new URL(e.response.url);
      event.contexts = {
        ...event.contexts,
        'Santa Response Info': {
          'Trace Id': e.traceId,
          'Request pathname': requestUrl.pathname,
          'Request params': requestUrl.search,
          'Request method': e.request.method,
          Code: e.santaErrorCode,
          Message: e.santaErrorMessage,
        },
      };
      event.fingerprint = [
        String(e.request.method),
        String(requestUrl.pathname.replaceAll(/[0-9]+/g, '_')),
        String(e.response.status),
        String(e.santaErrorCode),
      ];
    } else if (e instanceof ToeicSpeakingResponseError) {
      const requestUrl = new URL(e.response.url);
      event.contexts = {
        ...event.contexts,
        'Response Info': {
          'Trace Id': e.traceId,
          'Request pathname': requestUrl.pathname,
          'Request params': requestUrl.search,
          'Request method': e.request.method,
          Code: e.santaSayErrorCode,
          Message: e.santaSayErrorMessage,
        },
      };
      event.fingerprint = [
        String(e.request.method),
        String(requestUrl.pathname.replaceAll(/[0-9]+/g, '_')),
        String(e.response.status),
      ];
    } else if (e instanceof Error) {
      event.fingerprint = [e.name, '{{ default }}'];
    }
    return event;
  },
});
